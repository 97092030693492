import { Container, Link, Paper, SxProps, Theme, Typography } from "@mui/material";
import WebsiteTranslations from "../../translation/WebsiteTranslations";

const MembersArea = () => {

    const translation = WebsiteTranslations;
    const members_area = translation.members_area;

    const gutterBottom = {paddingBottom: (theme: Theme) => theme.spacing(2) } as SxProps<Theme>;
    
    const sx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: (theme: Theme) => theme.spacing(4),
        marginBottom: (theme: Theme) => theme.spacing(2)
    } as SxProps<Theme>;

    return (<Paper sx={{flex: 1, alignItems: "center", padding: (theme: Theme) => theme.spacing(4), overflow: "auto", background: "rgba(0, 0, 0, 0)"}}>
            <Container maxWidth = "lg" >
                <Paper sx={sx}>
                    <Typography variant="h6" sx={{...gutterBottom, textAlign: "center"}}>{members_area.title}</Typography>
                    <Typography sx={{textAlign: "center", whiteSpace: "pre-line"}} variant="body1" gutterBottom>
                        {members_area.text} <Link href="mailto:info@geko-ev.org">info@geko-ev.org</Link>
                    </Typography>
                </Paper>

                <Paper sx={sx}>
                    <Typography variant="h6" sx={{...gutterBottom, textAlign: "center"}}>{members_area.documents}</Typography>

                    <Typography sx={{textAlign: "center", whiteSpace: "pre-line"}} variant="body1" gutterBottom>
                        <Link href={process.env.PUBLIC_URL + "/docs/Mitgliedsantrag.pdf"} download>{members_area.application}</Link>
                    </Typography>
                    <Typography sx={{textAlign: "center", whiteSpace: "pre-line"}} variant="body1" gutterBottom>
                        <Link href={process.env.PUBLIC_URL + "/docs/Beitragsordnung.pdf"} download>{members_area.contribution} </Link>
                    </Typography>
                    <Typography sx={{textAlign: "center", whiteSpace: "pre-line"}} variant="body1" gutterBottom>
                        <Link href={process.env.PUBLIC_URL + "/docs/Satzung_22_11_2021.pdf"} download>{members_area.statutes} </Link>
                    </Typography>

                </Paper>
            </Container>
        </Paper>);
};

export default MembersArea;