
export const Categorys = {
  	start: 0,
    about_us: 1,
    news : 2,
    members: 3,
    projects: 4,
    imprint : 5,    
  };

export const CatergoryNames = {
  start    : "start",
  about_us : "about",
  news     : "news",
  members  : "member",
  projects : "projects",
  imprint  : "imprint",  
}

export const GetCategoryNameFromID = (id : number) => {
  switch(id)
  {
      case Categorys.start     : return CatergoryNames.start;
      case Categorys.news      : return CatergoryNames.news;
      case Categorys.about_us  : return CatergoryNames.about_us;
      case Categorys.members   : return CatergoryNames.members;
      case Categorys.projects  : return CatergoryNames.projects;
      case Categorys.imprint   : return CatergoryNames.imprint;
      default: console.warn("no category with specified id: " + id); return CatergoryNames.start;
  }
}

export const GetCategoryIDFromName = (name : string | null) => {

  switch(name)
  {
      case CatergoryNames.start     : return Categorys.start;
      case CatergoryNames.news      : return Categorys.news;
      case CatergoryNames.about_us  : return Categorys.about_us;
      case CatergoryNames.members   : return Categorys.members;
      case CatergoryNames.projects  : return Categorys.projects;
      case CatergoryNames.imprint   : return Categorys.imprint; 
      default: break;
  }

  if(name)
    console.warn("no category with specified name: " + name); 
  return Categorys.start;
} 

export const Languages = ["en", "de"];
  