import { Box, Container, IconButton, Link, Paper, SxProps, Theme, Typography } from "@mui/material";
import WebsiteTranslations from "../../translation/WebsiteTranslations";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Imprint = () => {

    const translation = WebsiteTranslations;
    const gutterBottom = {paddingBottom: (theme: Theme) => theme.spacing(2) } as SxProps<Theme>;
    
    return (<Paper sx={{flex: 1, alignItems: "center", padding: (theme: Theme) => theme.spacing(4), overflow: "auto", background: "rgba(0, 0, 0, 0)"}}>
            <Container maxWidth = "lg" >
                <Paper sx={{flex: 1, padding: (theme: Theme) => theme.spacing(5),}}>
                    <Typography variant="h6" sx={{paddingBottom: (theme: Theme) => theme.spacing(4) }}>{translation.imprint}:</Typography>
                    
                    <Typography variant="body1" sx={gutterBottom}>Garden Eden Kollektiv e. V.</Typography>
                    <Box sx={{...gutterBottom, display: 'flex'}} > 
                        <Box sx={{paddingRight: (theme: Theme) => theme.spacing(1) }}>
                            <Typography variant="body1">{translation.authorized_representative}</Typography>
                        </Box>
                        <Link href="mailto:info@geko-ev.org">Martin Kühn</Link>
                    </Box>
                    
                    <Typography variant="body1">Thälmannplatz 11</Typography>
                    <Typography variant="body1" sx={gutterBottom}>07426 Königsee</Typography>
                    <Typography variant="body1" sx={gutterBottom}>Deutsche Skatbank · IBAN: DE95 8306 5408 0005 2485 58</Typography>

                    <Typography variant="body1">Links zur Webseite:</Typography>
                    <Typography variant="body1"><Link href="https://geko-ev.org/">www.geko-ev.org</Link></Typography>
                    <Typography variant="body1" sx={gutterBottom}><Link href="https://garden-eden-kollektiv.de/">www.garden-eden-kollektiv.de</Link></Typography>
                    
                    {/*<Typography variant="body1">Telefon: (0123) 456789</Typography>
                    <Typography variant="body1" sx={gutterBottom}>Fax: (0123) 987654</Typography>*/}
                    <Box sx={{...gutterBottom, display: 'flex'}}>
                        <Typography variant="body1" sx={{paddingRight: (theme: Theme) => theme.spacing(1) }}>
                            E-Mail:
                        </Typography>
                        <Link href="mailto:info@geko-ev.org">info@geko-ev.org</Link>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: "center"}}>
                        <Typography variant="body1" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                            Facebook
                        </Typography>

                        <Link  href="https://www.facebook.com/profile.php?id=100087837693225" target="_blank" rel="noreferrer">
                            <IconButton size="small" sx={{marginLeft: (theme: Theme) => theme.spacing(1) }}>
                                <FacebookIcon/>
                            </IconButton>
                        </Link>
                    </Box>

                    <Box sx={{...gutterBottom, display: 'flex', alignItems: "center"}}>
                        <Typography variant="body1" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                            Instagram
                        </Typography>

                        <Link  href="https://www.instagram.com/garden_eden_kollektiv/" target="_blank" rel="noreferrer">
                            <IconButton size="small" sx={{marginLeft: (theme: Theme) => theme.spacing(1) }}>
                                <InstagramIcon/>
                            </IconButton>
                        </Link>
                    </Box>
                                     
                    <Typography variant="body2">{translation.register_associations}</Typography>
                </Paper>
            </Container>
        </Paper>);
};

export default Imprint;