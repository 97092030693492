import { Container, Link, Paper, SxProps, Theme, Typography } from "@mui/material";
import WebsiteTranslations from "../../translation/WebsiteTranslations";

const ProjectsArea = () => {

    const translation = WebsiteTranslations;
    const projects_area = translation.projects;

    const gutterBottom = {paddingBottom: (theme: Theme) => theme.spacing(2) } as SxProps<Theme>;
    
    const sx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: (theme: Theme) => theme.spacing(4),
        marginBottom: (theme: Theme) => theme.spacing(2)
    } as SxProps<Theme>;

    return (<Paper sx={{flex: 1, alignItems: "center", padding: (theme: Theme) => theme.spacing(4), overflow: "auto", background: "rgba(0, 0, 0, 0)"}}>
            <Container maxWidth = "lg" >
                <Paper sx={sx}>
                    <Typography variant="h6" sx={{...gutterBottom, textAlign: "center"}}>{projects_area.title}</Typography>
                    <Typography sx={{textAlign: "center", whiteSpace: "pre-line"}} variant="body1" gutterBottom>
                        {projects_area.text}
                    </Typography>
                </Paper>

                <Paper sx={sx}>
                    <Link
                        title="Jetzt spenden für „Allgemeine Vereinsarbeit“ auf betterplace.org!"
                        target="_blank"
                        href="https://www.betterplace.org/de/projects/116230-allgemeine-vereinsarbeit?utm_campaign=donate_btn&amp;utm_content=project%23116230&amp;utm_medium=external_banner&amp;utm_source=projects"
                        rel="noopener"
                    >
                            <img
                                style={{border:"0px"}}
                                alt="Jetzt Spenden! Das Spendenformular wird von betterplace.org bereit gestellt."
                                width="160"
                                height="100"
                                src="https://betterplace-assets.betterplace.org/static-images/projects/donation-button-de.png"
                            />
                        </Link>
                </Paper>

            </Container>
        </Paper>);
};

export default ProjectsArea;