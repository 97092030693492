import { Box, Theme } from "@mui/system";
import { Link, IconButton, Typography, Tooltip } from "@mui/material";

//import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VideoChatIcon from '@mui/icons-material/VideoChat';

const style = {
    flex: 1,
    display: "flex",
    padding : (theme:Theme) => theme.spacing(1),
    alignItems: 'center',
};

const Footer = () => {
    
    return ( <Box sx={style}>
                
                <Typography sx={{flex: 5,marginBottom : (theme) => theme.spacing(0)}}>© Garden Eden Kollektiv e.V. - 2024</Typography>
                
                <Box sx={{flex: 1}} />
                    <Box sx ={{alignSelf: "flex-end"}} >

                        <Tooltip title="Garden Eden Jitsi Videokonferenz">
                            <Link  href="https://meet.jit.si/GardenEdenKollektiv" target="_blank" rel="noreferrer">
                                <IconButton size="small" sx={{padding: (theme: Theme) => theme.spacing(1) }}>
                                    <VideoChatIcon/>
                                </IconButton>
                            </Link>
                        </Tooltip>

                        <Tooltip title="Garden Eden Facebook Page">
                            <Link  href="https://www.facebook.com/profile.php?id=100087837693225" target="_blank" rel="noreferrer">
                                <IconButton size="small" sx={{padding: (theme: Theme) => theme.spacing(1) }}>
                                    <FacebookIcon/>
                                </IconButton>
                            </Link>
                        </Tooltip>

                        <Tooltip title="Garden Eden Instagram Page">
                            <Link  href="https://www.instagram.com/garden_eden_kollektiv/" target="_blank" rel="noreferrer">
                                <IconButton size="small" sx={{padding: (theme: Theme) => theme.spacing(1) }}>
                                    <InstagramIcon/>
                                </IconButton>
                            </Link>
                        </Tooltip>
                        
                        <Tooltip title="Garden Eden Kontakt">
                            <Link  href="mailto:info@geko-ev.org" target="_top" rel="noreferrer">
                                <IconButton size="small" sx={{padding: (theme: Theme) => theme.spacing(1) }}>
                                    <MailOutlineIcon/>
                                </IconButton>
                            </Link>
                        </Tooltip>

                    </Box>
            </Box>);
};

export default Footer;