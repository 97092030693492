import LocalizedStrings from "react-localization";

const WebsiteTranslations = new LocalizedStrings({
    en:{
      darkMode:"Enable dark mode",
      lightMode:"Enable light mode",

      standardMode: "Standard",
      colorMode: "Colorful",
  
      menu: "Menu",
      language: "Language",
      events: "Events",
      account: "My account",
      about: "About",
      to: "to",
  
      name: "Garden Eden Kollektiv e.V.",
      pages: ['Start', 'About us', 'News', 'Become a member', 'Support us', 'Imprint'],

      jufoe: {
        name: "Jugendförderverein Saalfeld-Rudolstadt e.V.",
        info_title: "Important Information for Our Shuttle Service of the",
        info_1: "We are offering a special shuttle service for the youth festival.",
        info_2: "To use this service, please fill out the two documents provided below.",
        thanks: "Thank you for your cooperation!",
        shuttle_u18: "Declaration of consent for use of the Lichta youth event transport shuttle for under 18s",
        shuttle_adult: "Declaration of consent for the use of the Lichta youth event transport shuttle for adults",
      },

      members_area:{
         title: "You want to become a member?",
         text: "An association can only exist through reliable and creative members.\nIf you feel connected to the values of the association, we would like to offer you the opportunity to actively participate in the life of the association.\n\nPlease send us your completed membership application to",
         documents: "Available Documents",
         application: "membership application",
         contribution: "Schedule of contributions",
         statutes: "Statutes"
        },
      imprint: "Imprint",
      documents:
      {
        main: "Documents",
        parenting:{
          link: "Document for parental authorization for the duration of the dance event.",
          head:"Important Information for Youth Under 16",
          info_1: "Please note that all youth under 16 years of age, attending the event without their parents, must bring the signed Parental Consent Form in duplicate. Without this document, entry will not be permitted.",
          thanks: "Thank you for your understanding, and we look forward to a great event with you!",
        },
      },
      authorized_representative: "Authorized representative of the Board of Directors",
      register_associations: "Registered in the register of associations at the district court of Rudolstadt under VR 260857",
      
      link_list:{
        title:"News",
        text: "We provide you with regular updates on our planned and ongoing campaigns on our social media channels. Follow us and stay up to date.",
        facebook: "Visit us on Facebook.",
        instgram: "Visit us on Instagram."
      },
      about_us : {
        title: "About us",
        text: "Our association pursues the promotion of art and culture, local beautification and landscape preservation as well as nature conservation. These goals are to be achieved through various concert events, workshops and the support of other institutions at cultural events in the Königsee area. Also the reorganization of recreational destinations, upgrading measures of the townscape and garbage collection campaigns shall be among recurring association activities."
      },      
      our_goals: {
        title: "Our Goals",
        text:"The association pursues the goal of enhancing the quality of life and everyday life in and around Königsee in a variety of ways. The low-threshold offers should be directed at all people of different colors and also strengthen different sub- and youth cultures in the Königsee region.",
      },
      news: {
        participate : {
          id: 0,
          title: "Garden Eden Kollektiv e.V. - Garbage collection 17.09.2022 ",
          img: "./image/world-cleanup-day-2022.png",
          img_alt: "World CleanUp Day",
          date: "September 17, 2022",
          text: "On Saturday, 17.09.2022, we have carried out a garbage collection action in the context of the 'World Cleanup Day'.",
          detail_text: [
            "In partly adverse weather conditions 8 club members have come together and collected between Königsee and Lichta in 3 hours about 130 kg of garbage. Beside plastic, glass and porcelain garbage also car tires, carpets and a sink table were found.",
            "The disposal was kindly organized by the municipality of Königsee.",
            ]    
        },
        christmas2022:{
          id: 1,
          title: "Garden Eden Kollektiv e.V. - Christmas Market Königsee 9.- 11.12.2022",
          img: "./image/christmas-market-2022.jpg",
          img_alt: "Christmas Market  Königsee 2022",
          date: "14.12.2022",
          text: "A complete success! That's what we call the Christmas market in Königsee and we are happy to have contributed our part to it.",
          detail_text: [
            "We would like to thank all participants and helpers for these wonderful 3 days in a festive mood. With our offer of hot drinks and tarte flambée, we rocked the market and received a lot of positive feedback from all sides.",
            "Have we perhaps also aroused your interest in great projects with us?  Get in touch we are happy about everyone who wants to actively make Königsee more attractive with us.",
            "Merry Christmas to you all and we hope to see you again soon."
          ]
        }      
      },
      projects:{
        title: "Support us with the projects we have planned.",
        text: "Since we are a young association we need financial support for the implementation of our association activities. For this we have created a project on Betterplace.org where you can help us.\nAn overview and more information about how and which items are planned for the coming months can be found below. ",
      }
    },
    de:{
      darkMode:"Aktiviere Dunkles Thema",
      lightMode:"Aktiviere Helles Thema",

      standardMode: "Standard",
      colorMode: "Farbenfroh",
  
      menu: "Menü",
      language: "Sprache",
      events: "Veranstaltungen",
      account: "Mein Account",
      about: "Über Uns",
      to: "an",
  
      name: "Garden Eden Kollektiv e.V.",
      pages: ['Start', 'Über uns', 'Aktuelles', 'Mitglied werden', "Unterstütze uns", 'Impressum'],

      jufoe: {
        name: "Jugendförderverein Saalfeld-Rudolstadt e.V.",
        info_title: "Wichtige Information für unseren Shuttle Service des",
        info_1: "Für das Jugendfest bieten wir einen speziellen Shuttle Service an.",
        info_2: "Um diesen Service in Anspruch zu nehmen, bitten wir Sie, die beiden unten bereitgestellten Dokumente auszufüllen.",
        thanks: "Vielen Dank für Ihre Mithilfe!",
        shuttle_u18: "Einverständniserklärung Nutzung Transportshuttle des Jugendevents Lichta für unter 18 Jährige",
        shuttle_adult: "Einverständniserklärung Nutzung Transportshuttle des Jugendevents Lichta für Erwachsene",
      },

      members_area:{
        title: "Du möchtest Mitglied werden?",
        text: "Ein Verein kann nur durch zuverlässige und kreative Mitglieder bestehen.\nFalls du dich mit den Werten des Vereins verbunden fühlst, möchten wir dir gern die Möglichkeit bieten dich aktiv im Vereinsleben einzubringen.\n\nBitte sende uns hierfür deinen ausgefüllten Mitgliedsantrag an",
        documents: "Verfügbare Dokumente",
        application: "Mitgliedsantrag",
        contribution: "Beitragsordnung",
        statutes: "Satzung"
       },
      imprint: "Impressum",
      documents:
      {
        main: "Dokumente",
        parenting:{
          link: "Dokument für Erziehungsbeauftragung für die Dauer der Tanzveranstaltung (Muttizettel).",
          head:"Wichtige Information für Jugendliche unter 16 Jahren",
          info_1: "Bitte beachtet, dass alle Jugendlichen unter 16 Jahren, die ohne Begleitung ihrer Eltern zur Veranstaltung kommen, das Dokument zur Erziehungsbeauftragung (Muttizettel) unterschrieben und in doppelter Ausführung mitbringen müssen. Ohne dieses Dokument ist ein Einlass leider nicht möglich.",
          thanks: "Wir danken für euer Verständnis und freuen uns auf eine tolle Veranstaltung mit euch!",
        },
      },
      authorized_representative: "Vertretungsberechtigter Vorstand",
      register_associations: "Eingetragen in das Vereinsregister beim Amtsgericht Rudolstadt unter VR 260857",

      link_list:{
        title:"Aktuelles",
        text: "Wir bieten Dir auf unseren Social Media-Kanälen regelmäßig aktuelle Infos zu unseren geplanten und laufenden Aktionen. Folge uns gern und bleib auf dem Laufenden.",
        facebook: "Besuche uns auf Facebook.",
        instgram: "Besuche uns auf Instagram."
      },
      about_us : {
        title: "Über uns",
        text: "Unser Verein verfolgt die Zwecke der Förderung der Kunst und Kultur, der Ortsverschönerung und der Landschaftspflege sowie des Naturschutzes. Erfüllt werden sollen die Zwecke durch verschiedene Konzertveranstaltungen, Workshops und die Unterstützung anderer Institutionen bei Kulturveranstaltungen im Raum Königsee. Auch die Sanierung von Erholungszielpunkten, Aufwertungsmaßnahmen des Ortsbildes und Müllsammelaktionen sollen zu wiederkehrenden Vereinsaktivitäten zählen."
      },
      our_goals: {
        title: "Unsere Ziele",
        text:"Der Verein verfolgt das Ziel, das Leben und den Alltag in und um Königsee auf vielfältiger Weise aufzuwerten. Die niedrigschwelligen Angebote sollen sich an alle Menschen verschiedener Couleur richten und auch verschiedene Sub- und Jugendkulturen im Königseer Umfeld stärken."
      },
      news: {
        participate : {
          id: 0,
          title: "Garden Eden Kollektiv e.V. - Müllsammelaktion 17.09.2022 ",
          img: "./image/world-cleanup-day-2022.png",
          img_alt: "World CleanUp Day",
          date: "17. September, 2022",
          text: "Am Samstag, den 17.09.2022, haben wir im Rahmen des 'World Cleanup Day' eine Müllsammelaktion durchgeführt.",
          detail_text: [
            "Bei teils widrigen Wetterbedingungen haben sich 8 Vereinsmitglieder eingefunden und zwischen Königsee und Lichta in 3 Stunden ca. 130 kg Müll gesammelt. Neben Plastik-, Glas- und Porzellanmüll wurden auch Autoreifen, Teppiche und ein Spültisch gefunden.",
            "Die Entsorgung wurde freundlicher Weise durch die Stadtverwaltung Königsee organisiert.",
          ],
        },
        christmas2022:{
          id: 1,
          title: "Garden Eden Kollektiv e.V. - Königseer Weihnachtsmarkt 9.- 11.12.2022",
          img: "./image/christmas-market-2022.jpg",
          img_alt: "Weihnachtsmarkt Königsee 2022",
          date: "14.12.2022",
          text: "Ein voller Erfolg! So nennen wir den Weihnachtsmarkt in Königsee und freuen uns unseren Teil dazu beigetragen zu haben.",
          detail_text: [
            "Wir bedanken uns bei allen Beteiligten und Helfern für diese wundervollen 3 Tage in festlicher Stimmung. Mit unserem Angebot aus Heißgetränken und Flammkuchen rockten wir den Markt und bekamen von allen Seiten viel positives Feedback.",
            "Haben wir vielleicht auch dein Interesse an tollen Projekten mit uns geweckt? Meldet euch wir freuen uns über jeden der mit uns Königsee aktiv attraktiver machen möchte.",
            "Euch allen ein herzliches Weihnachtsfest und wir hoffen euch bald wieder zu sehen."
          ]
        }
      },
      projects:{
        title: "Unterstütze uns bei den geplanten Projekten.",
        text: "Da wir ein junger Verein sind benötigen wir finanzielle Unterstützung bei der Umsetzung unserer Vereinsaktivitäten. Hierzu haben wir ein Projekt auf Betterplace.org angelegt wo du uns gerne dabei helfen kannst.\nEinen Überblick und weitere Informationen wie und welche Posten für die kommenden Monate vorgesehen sind findest du nachfolgend. ",
      }
    },
  }
);
export default WebsiteTranslations;