import { Container, Paper, SxProps, Theme, Typography } from "@mui/material";
import WebsiteTranslations from "../../translation/WebsiteTranslations";

const AboutUs = () => {

    const translation = WebsiteTranslations;
    const about_us = translation.about_us;
    const our_goals = translation.our_goals;

    const sx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: (theme: Theme) => theme.spacing(4),
        marginBottom: (theme: Theme) => theme.spacing(2)
    } as SxProps<Theme>;

    return (<Paper sx={{flex: 1, alignItems: "center", padding: (theme: Theme) => theme.spacing(4), overflow: "auto", background: "rgba(0, 0, 0, 0)"}}>
                <Container maxWidth = "lg" >
                    <Paper sx={sx}>
                        <Typography variant="h5" gutterBottom>{about_us.title}</Typography>
                        <Typography sx={{textAlign: "center"}} variant="body1" gutterBottom>{about_us.text}</Typography>
                    </Paper>

                    <Paper sx={sx}>
                        <Typography variant="h5" gutterBottom>{our_goals.title}</Typography>
                        <Typography sx={{textAlign: "center"}} variant="body1" gutterBottom>{our_goals.text}</Typography>
                    </Paper>
                </Container>
            </Paper>);
};

export default AboutUs;