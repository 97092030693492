import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { category } from "../../redux/reducer/reducer-category";
import { language, setLanguage } from "../../redux/reducer/reducer-language";

import { Categorys } from "../Constants";

import Header from "../Header";
import Footer from "../Footer";

import LinkList from "./LinkList";
import MembersArea from "./Members";
import Imprint from "./Imprint";
import AboutUs from "./AboutUs";
import WebsiteTranslations from "../../translation/WebsiteTranslations";
import StartPageView from "./StartPage";

import { useEffect, useState } from "react";
import { coloring } from "../../redux/reducer/reducer-coloring";

import "./../../css/Gradient.css";
import ProjectsArea from "./Projects";

const MainView = () => {

  const[, setState] = useState({});

    // gather redux data
    const cat = useAppSelector(category);
    const lang = useAppSelector(language);
    const dispatch = useAppDispatch();

    const translation = WebsiteTranslations;
    const SetLanguage = (lang : string) => {
      dispatch(setLanguage(lang));
    };

    useEffect(() => {
      // set language, setState needs to be called to refresh the page
      SetLanguage(lang);
      setState({});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    const colorMode = useAppSelector(coloring);
    const RenderView = () => {
  
      switch(cat)
      {
        case Categorys.start  : return <StartPageView />;
        case Categorys.news : return <LinkList />;
        case Categorys.about_us  : return <AboutUs/>;
        case Categorys.members : return <MembersArea/>;
        case Categorys.projects : return <ProjectsArea/>;
        case Categorys.imprint : return <Imprint/>;
        default: console.error("Invalid category selected."); return <div/>;
      }
    };
  
    return (
      <Box className={colorMode ? "gradientbody" : "body"} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
      }}>
        <Header        
          name={translation.name}
          pages={translation.pages}
          lightMode={translation.lightMode}
          darkMode={translation.darkMode}
          language={translation.language}
          colorMode={translation.colorMode}
          standardMode={translation.standardMode}
          setLanguage={SetLanguage}/>
  
        <Box sx={{flex: 30, display: 'flex', overflow: 'auto'}}>
          <RenderView />
        </Box>
  
        <Footer/>       
      </Box>    
    );
  };

  export default MainView;