import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface CategoryState {
  value: number;
}

const initialState: CategoryState = {
  value: 0,
};

export const categorySlice = createSlice({
  name: 'Category',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCategory: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const { setCategory } = categorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const category = (state: RootState) => state.category.value;

export default categorySlice.reducer;
