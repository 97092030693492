import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Categorys } from '../../components/Constants';
import { RootState } from '../store';

export interface ArticleState {
  category: number,
  id: number;
}

const initialState: ArticleState = {
    category: Categorys.news,
    id: -1,
};

export const articleSlice = createSlice({
  name: 'Article',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setArticle: (state, action: PayloadAction<ArticleState>) => {
      state = action.payload;
    },
  },
});

export const { setArticle } = articleSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const article = (state: RootState) => state.article;

export default articleSlice.reducer;