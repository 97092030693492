import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import darkModeReducer from '../redux/reducer/reducer-darkmode';
import languageReducer from '../redux/reducer/reducer-language';
import categoryReducer from '../redux/reducer/reducer-category';
import articleReducer from '../redux/reducer/reducer-article';
import coloringReducer from '../redux/reducer/reducer-coloring';

export const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    language: languageReducer,
    category: categoryReducer,
    article: articleReducer,
    coloring: coloringReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
