import {useMemo, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from './redux/hooks';

import {setDarkMode, darkMode} from './redux/reducer/reducer-darkmode'
import { setLanguage } from './redux/reducer/reducer-language';
import { setCategory } from './redux/reducer/reducer-category';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { Categorys, GetCategoryIDFromName } from './components/Constants';
//import MainView from './components/Views/Main';
import { useSearchParams } from 'react-router-dom';
import { setArticle } from './redux/reducer/reducer-article';
import MainView from './components/Views/Main';
import { enableColoring } from './redux/reducer/reducer-coloring';

import "./css/Fireflies.css"

interface AppProps {
  category: number,
};


// App gathering startup data and set up theme
export const App = (props : AppProps) => {

  const mode = useAppSelector(darkMode);
  const dispatch = useAppDispatch();

  const [params, /*setParams*/] = useSearchParams();

  useEffect(() => {
    dispatch(enableColoring(params.get("default") != null))
    dispatch(setDarkMode(true));
    dispatch(setLanguage(navigator.language));
    dispatch(setCategory(props.category));

    // read category from query
    const cat = props.category === 0 ? GetCategoryIDFromName(params.get("category")) : props.category;
    dispatch(setCategory(cat));

    // read specified id and try parse it
    let id = parseInt(params.get("id") ?? "-1");
    if(id !== -1)
    {
      // check if it is in range
      if(id < 0)
        id = -1;

      switch(cat)
      {
        //case Categorys.events:
        case Categorys.news: dispatch(setArticle({category: cat, id: id})); break; // set specified article
        default: break;
      }
    }

    // reset querys after startup
    //const id_query = id > -1 ? "&id="  + id : "";
    //setParams("category="+GetCategoryNameFromID(cat) + id_query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode ? 'dark' : 'light',
          background: {
            paper: mode ? "rgba(42, 42, 42, 0.9)" : "rgba(244, 244, 244, 0.9)",
          }
        },
      }),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainView/>

      {/*Draw Fireflies here*/}
      {Array.from({length: 25}).map((it, idx) => <div key={"firefly-"+idx} className="firefly"></div> ) }
    </ThemeProvider>
  );
};

export default App;