import React from 'react';
import { createRoot } from 'react-dom/client';

//import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Categorys } from './components/Constants';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    < BrowserRouter >
      <Provider store={store}>
        <Routes>
          < Route path="/" element={<App category={Categorys.start}/>} />  
          < Route path="/about" element={<App category={Categorys.about_us}/>} />
          < Route path="/news" element={<App category={Categorys.news}/>} />
          < Route path="/documents" element={<App category={Categorys.news}/>} />
          < Route path="/members" element={<App category={Categorys.members}/>} />
          < Route path="/projects" element={<App category={Categorys.projects}/>} />
          < Route path="/imprint" element={<App category={Categorys.imprint}/>} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
